<template>
    <marketing-layout>
        <marketing-hero>
            <h1
                class="fw-bold heading-3 text-responsive mb-3 text-md-center"
                v-html="$t('pages.marketing.about.titleHtml')"
            />
            <h2 class="fw-light h5 text-responsive mb-0 text-md-center">
                {{ $t('pages.marketing.about.subTitle') }}
            </h2>
        </marketing-hero>

        <section-block class="bg-light">
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h3 class="text-reponsive">
                            Re-inventing capital.<br>
                            Built for security.
                        </h3>
                        <p>
                            We are partnered with Coastal Community Bank, member FDIC, pursuant to license by VISA USA Inc. You benefit from our innovation, great pricing, and simple convenience with
                            the trust of a traditional FDIC-insured bank and the Visa network.
                        </p>
                    </div>
                    <div class="col-12 col-md-3 offset-md-1 d-flex flex-md-column align-items-start justify-content-md-center align-items-md-center pt-5 pt-md-8">
                        <img
                            src="@/assets/images/pages/marketing/about/visa.jpg"
                            alt="Visa Card"
                            height="52"
                            class="me-5 me-md-0"
                        >
                        <img
                            src="@/assets/images/pages/marketing/about/coastal.jpg"
                            alt="Coastal"
                            height="64"
                            class="mt-md-5 mt-md-0"
                        >
                    </div>
                </div>
            </div>
        </section-block>

        <section-block>
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 col-md-5 order-md-1">
                        <h3 class="text-reponsive">
                            Backed by top tier investors
                        </h3>
                        <p>
                            Aven is funded by a number of the largest investors with deep expertise in financial services and technology. These are the same investors that backed companies like
                            Square, Affirm, Stripe and PayPal.
                        </p>
                    </div>
                    <div class="col-12 col-md-7 d-flex flex-column order-md-0 mt-5 mt-md-0">
                        <investors />
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'
    import MarketingHero from '@/components/MarketingHero'
    import Investors from '@/components/Investors'

    export default {
        name: 'About',
        metaInfo: {
            title: 'About',
            meta: [{ name: 'description', vmid: 'description', content: 'Our mission is to provide the lowest cost, most convenient, and most transparent access to capital.' }],
        },
        mounted: async function () {
            this.$logEvent('view_about')
        },
        components: {
            Investors,
            'marketing-layout': Marketing,
            SectionBlock,
            MarketingHero,
        },
    }
</script>
